import {Trans, WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Button from '../Button/Button'

import styles from './WelcomeBanner.module.scss'

const FORM_LINK = 'https://forms.gle/DAFM4mL5xVGCxVZ96'
const WelcomeBanner = ({t}: WithTranslation) => (
  <>
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <section className={styles.textWrapper}>
          <span>{t('welcomeBanner.title')}</span>
          <span>
            <Trans i18nKey="welcomeBanner.subtitle">
              Económico, social
              <br />& ambiental
            </Trans>
          </span>
          <Button className={styles.button}>
            <a
              className={styles.linkButton}
              href={FORM_LINK}
              target="_blank"
              rel="noopener noreferrer">
              {t('register')}
            </a>
          </Button>
        </section>
        <span className={styles.footer}>{t('welcomeBanner.footer')}</span>
      </BannerContainer>
    </BannerSection>
    <BannerSection className={styles.banner}>
      <span>{t('NUEVAS SOLUCIONES PARA LOS NUEVOS DESAFIOS')}</span>
    </BannerSection>
  </>
)

export default withTranslation()(WelcomeBanner)
