import {Trans, WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import approaches from './approaches.png'
import goals from './goals.png'

import styles from './SustainableDevelopmentGoals.module.scss'

const SustainableDevelopmentGoals = ({t}: WithTranslation) => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      <img src={goals} />
      <section>
        <span>{t('sustainableDevelopmentGoals.title')}</span>
        <span>
          <Trans i18nKey="sustainableDevelopmentGoals.description">
            first part
            <br />
            second part
          </Trans>
        </span>
        <span>{t('sustainableDevelopmentGoals.approaches')}</span>
        <img src={approaches} />
      </section>
    </BannerContainer>
  </BannerSection>
)

export default withTranslation()(SustainableDevelopmentGoals)
