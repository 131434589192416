import {ReactNode, useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import {ReactComponent as Circle} from './circle.svg'
import {ReactComponent as Lamp} from './lamp.svg'
import {ReactComponent as Note} from './note.svg'
import {ReactComponent as ShareCircle} from './share-circle.svg'

import styles from './ValueLayer.module.scss'

interface IValue {
  key: string
  logo: ReactNode
}

const ValueLayer = ({t}: WithTranslation) => {
  const values = useMemo<IValue[]>(
    () => [
      {key: 'card1', logo: <Lamp />},
      {key: 'card2', logo: <Circle />},
      {key: 'card3', logo: <Note />},
      {key: 'card4', logo: <ShareCircle />},
    ],
    [t],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>{t('valueLayer.title')}</span>
          <span className={styles.subtitle}>{t('valueLayer.subtitle')}</span>
        </div>
        <div className={styles.cards}>
          {values.map(value => (
            <div key={value.key} className={styles.card}>
              {value.logo}
              <span>{t(`valueLayer.cards.${value.key}.title`)}</span>
              <span>{t(`valueLayer.cards.${value.key}.description`)}</span>
            </div>
          ))}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(ValueLayer)
