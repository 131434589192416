import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Button from '../Button/Button'

import CircleGraphic from './circle-graphic.png'

import styles from './CollaborativeSpace.module.scss'

const CollaborativeSpace = ({t}: WithTranslation) => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      <section>
        <span>{t('collaborativeSpace.title')}</span>
        <span>{t('collaborativeSpace.description')}</span>
        <Button filledColor="primary">
          <a
            href={'https://muskuy.d13cj0nxoofr72.amplifyapp.com/'}
            target="_blank"
            rel="noopener noreferrer">
            Marketplace Hub3i
          </a>
        </Button>
      </section>
      <section>
        <img src={CircleGraphic} />
      </section>
    </BannerContainer>
  </BannerSection>
)

export default withTranslation()(CollaborativeSpace)
