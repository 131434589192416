import {useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import APPI from './partnerIcons/APPI.png'
import CIHALC from './partnerIcons/CIHALC.png'
import criptokuantica from './partnerIcons/criptokuantica.png'
import ElNucleo from './partnerIcons/el-nucleo.png'
import ENRealEstate from './partnerIcons/EN-Real-estate.png'
import geoestrategias from './partnerIcons/geoestrategias.png'
import ONU from './partnerIcons/ONU.png'
import UTN from './partnerIcons/UTN.png'

import styles from './Partners.module.scss'

interface IPartner {
  id: number
  iconSrc: string
}

const Partners = ({t}: WithTranslation) => {
  const partners = useMemo<IPartner[]>(
    () => [
      {
        id: 1,
        iconSrc: CIHALC,
      },
      {
        id: 2,
        iconSrc: ONU,
      },
      {
        id: 3,
        iconSrc: UTN,
      },
      {
        id: 4,
        iconSrc: ENRealEstate,
      },
      {
        id: 5,
        iconSrc: APPI,
      },
      {
        id: 6,
        iconSrc: ElNucleo,
      },
      {
        id: 8,
        iconSrc: criptokuantica,
      },
      {
        id: 9,
        iconSrc: geoestrategias,
      },
    ],
    [t],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>{t('partners.title')}</span>
        </div>
        <section>
          <div className={styles.partners}>
            {partners.map(partner => (
              <img key={partner.id} src={partner.iconSrc} />
            ))}
          </div>
        </section>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Partners)
