import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './ProjectsLink.module.scss'

const ProjectsLink = ({t}: WithTranslation) => {
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>{t('projectsLink.title')}</span>
          <ul>
            <li>{t('projectsLink.bonuses')}</li>
            <li>{t('projectsLink.impactCredits')}</li>
            <li>{t('projectsLink.selfIssuedGreenTokens')}</li>
          </ul>
        </div>
        <section>
          <span>{t('projectsLink.description1')}</span>
          <span>{t('projectsLink.description2')}</span>
        </section>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(ProjectsLink)
