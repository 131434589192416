import {useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Card from '../Card/Card'

import marcelo from './marcelo.png'
import nestor from './nestor.png'
import sergio from './sergio.png'

import styles from './Team.module.scss'

interface IMember {
  name: string
  imageSrc: string
}

const Team = ({t}: WithTranslation) => {
  const members = useMemo<IMember[]>(
    () => [
      {
        name: 'marcelo',
        imageSrc: marcelo,
      },
      {
        name: 'sergio',
        imageSrc: sergio,
      },
      {
        name: 'nestor',
        imageSrc: nestor,
      },
    ],
    [t],
  )
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span>{t('team.title')}</span>
          <span>{t('team.description')}</span>
        </div>
        <div className={styles.members}>
          {members.map(member => (
            <Card key={member.name} className={styles.card}>
              <div className={styles.preview}>
                <img src={member.imageSrc} />
                <span className={styles.name}>{t(`team.members.${member.name}.name`)}</span>
                <span className={styles.socials}></span>
              </div>
              <div className={styles.text}>
                <span className={styles.description}>
                  {t(`team.members.${member.name}.description`)}
                </span>
              </div>
            </Card>
          ))}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Team)
