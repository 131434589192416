import {useEffect, useState} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import notebook from '../../assets/images/notebook.png'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import useScrollPosition from '../../hooks/useScroll'
import useScrollAfterComponent from '../../hooks/useScrollAfterComponent'
import useScrollBeforeComponent from '../../hooks/useScrollBeforeComponent'
import useScrollingDown from '../../hooks/useScrollingDown'

import styles from './Marketplace.module.scss'

const COMPONENT_ID = 'marketplace'

const values: string[] = [
  'marketplace',
  'smartContracts',
  'wallet',
  'visualization',
  'investorInfo',
  'whitelist',
  'transactionability',
  'traceability',
]

const Marketplace = ({t}: WithTranslation) => {
  const [animationEnabled, enableAnimation] = useState<boolean>(false)
  const isScrollYBeforeComponent = useScrollBeforeComponent(COMPONENT_ID)
  const isScrollYAfterComponent = useScrollAfterComponent(COMPONENT_ID)
  const topComponentPositionPassed = useScrollPosition(COMPONENT_ID, 'top', 400)
  const isScrollingDown = useScrollingDown()

  useEffect(() => {
    if (isScrollYBeforeComponent && animationEnabled) enableAnimation(false)
    else if (isScrollingDown && topComponentPositionPassed && !animationEnabled)
      enableAnimation(true)
  }, [topComponentPositionPassed, isScrollingDown, isScrollYBeforeComponent])

  return (
    <BannerSection className={styles.section} id={COMPONENT_ID}>
      <BannerContainer
        className={classNames(styles.container, {
          [styles.scrollable]: !isScrollYBeforeComponent && !isScrollYAfterComponent,
        })}
        layout="column">
        <div
          className={classNames(styles.first, {
            [styles.hidden]:
              !isScrollYAfterComponent && (!animationEnabled || isScrollYBeforeComponent),
            [styles.animate]: animationEnabled,
          })}>
          <span className={styles.title}>
            <Trans i18nKey="marketplace.title">
              Brindamos un <strong>marketplace</strong> con
            </Trans>
            :
          </span>
          <img src={notebook} />
        </div>
        <div className={styles.second}>
          {values.map(value => (
            <div key={value} className={styles.value}>
              <span className={styles.title}>{t(`marketplace.values.${value}`)}</span>
              <span className={styles.subtitle}>{t(`marketplace.values.${value}Description`)}</span>
            </div>
          ))}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Marketplace)
