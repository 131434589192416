import CollaborativeSpace from '../../components/CollaborativeSpace/CollaborativeSpace'
import ContactUs from '../../components/ContactUs/ContactUs'
import Ecosystem from '../../components/Ecosystem/Ecosystem'
import Icons3iBanner from '../../components/Icons3iBanner/Icons3iBanner'
import ImproveProjects from '../../components/ImproveProjects/ImproveProjects'
import Marketplace from '../../components/Marketplace/Marketplace'
import Partners from '../../components/Partners/Partners'
import ProjectsLink from '../../components/ProjectsLink/ProjectsLink'
import RegionalFocus from '../../components/RegionalFocus/RegionalFocus'
import SustainableDevelopmentGoals from '../../components/SustainableDevelopmentGoals/SustainableDevelopmentGoals'
import Team from '../../components/Team/Team'
import ValueLayer from '../../components/ValueLayer/ValueLayer'
import WelcomeBanner from '../../components/WelcomeBanner/WelcomeBanner'

const Home = () => (
  <>
    <WelcomeBanner />
    <Ecosystem />
    <SustainableDevelopmentGoals />
    <RegionalFocus />
    <ValueLayer />
    <Icons3iBanner />
    <CollaborativeSpace />
    <ProjectsLink />
    <Marketplace />
    <Team />
    <ImproveProjects />
    <Partners />
    <ContactUs />
  </>
)

export default Home
