import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import {ReactComponent as Logo} from './logo.svg'

import styles from './Ecosystem.module.scss'

const values: string[] = [
  'impact3i',
  'organization',
  'innovation',
  'approach',
  'technology',
  'tools',
]
const Ecosystem = ({t}: WithTranslation) => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      <section>
        <span>{t('ecosystem.title')}</span>
        <span>{t('ecosystem.subtitle')}</span>
      </section>
      <Logo className={styles.logo} />
      <section>
        {values.map(value => (
          <p key={value}>
            <span>{t(`ecosystem.values.${value}.title`)}</span>
            <span>{t(`ecosystem.values.${value}.subtitle`)}</span>
            <span>{t(`ecosystem.values.${value}.description`)}</span>
          </p>
        ))}
      </section>
    </BannerContainer>
  </BannerSection>
)

export default withTranslation()(Ecosystem)
