import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import {ReactComponent as Acceleration} from './icons3i/acceleration.svg'
import {ReactComponent as Assessment} from './icons3i/assessment.svg'
import {ReactComponent as Design} from './icons3i/design.svg'
import {ReactComponent as Financing} from './icons3i/financing.svg'
import {ReactComponent as Marketplace} from './icons3i/marketplace.svg'
import {ReactComponent as Traceability} from './icons3i/traceability.svg'

import styles from './Icons3iBanner.module.scss'
const icons3i = [
  {label: 'design', icon: <Design />},
  {label: 'assesment', icon: <Assessment />},
  {label: 'acceleration', icon: <Acceleration />},
  {label: 'marketplace', icon: <Marketplace />},
  {label: 'traceability', icon: <Traceability />},
  {label: 'financing', icon: <Financing />},
]

const Icons3iBanner = ({t}: WithTranslation) => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      <section>
        <div className={styles.box}>
          {icons3i.slice(0, 3).map(icon3i => (
            <div key={icon3i.label} className={styles.card}>
              {icon3i.icon}
              <span>{t(`icons3i.${icon3i.label}`)}</span>
            </div>
          ))}
        </div>
        <div className={styles.box}>
          <span>Blockchain</span>
          {icons3i.slice(3, 6).map(icon3i => (
            <div key={icon3i.label} className={styles.card}>
              {icon3i.icon}
              <span>{t(`icons3i.${icon3i.label}`)}</span>
            </div>
          ))}
        </div>
      </section>
    </BannerContainer>
  </BannerSection>
)

export default withTranslation()(Icons3iBanner)
