import {WithTranslation, withTranslation} from 'react-i18next'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Button from '../Button/Button'

import styles from './RegionalFocus.module.scss'

const RegionalFocus = ({t}: WithTranslation) => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      <section>
        <span>{t('regionalFocus.title')}</span>
        <span>{t('regionalFocus.description')}</span>
        <Button filledColor="primary">
          <a
            href={'https://muskuy.d13cj0nxoofr72.amplifyapp.com/'}
            target="_blank"
            rel="noopener noreferrer">
            Marketplace Hub3i
          </a>
        </Button>
      </section>
    </BannerContainer>
  </BannerSection>
)

export default withTranslation()(RegionalFocus)
